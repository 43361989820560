@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&display=swap");

:root {
  --headerheight: 50px;
  zoom: 100%;
}

html,
body {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}

.ant-table-content {
  overflow: auto hidden;
  font-size: smaller;
}

.login {
  /* Size & position */
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(assets/backgroundlogin.jpg);

  .inner {
    min-width: 400px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cecece;
      margin-bottom: 20px;
    }
  }
}

.layout {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  display: flex;

  .rightitem {
    display: flex;
    align-items: center;
  }

  .header {
    height: var(--headerHeight);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightNav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }

    .text {
      color: whitesmoke;
    }
  }
}

.logoutButton {
  padding: 7px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.bodyHolder {
  width: 100%;
  min-height: calc(100vh - var(--headerHeight));
  display: flex;
  background: linear-gradient(rgba(247, 247, 247, 0.5), rgba(247, 247, 247, 1));
  position: relative;
  overflow: hidden;
}
.MysideBar ul.css-ewdv3l li a img {
  width: 18px;
}
.css-1wvake5 {
  width: 290px !important;
  min-width: 290px !important;
}

.css-1wvake5.ps-collapsed {
  width: 65px !important;
  min-width: 65px !important;
}

.css-16jesut > .ps-menu-button {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.css-1t8x7v1 > .ps-menu-button {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.MysideBar {
  border: none !important;
  box-shadow: none !important;
  padding: 10px !important;
  .css-dip3t8 {
    background-color: white;
    scrollbar-width: thin;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 8px;
    gap: 480px;
    background: #fbfbfb;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }

  .css-dip3t8::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(255, 255, 255, 0.5),
      #f5f5f5 70%
    );
    opacity: 0.5;
  }

  .menu1 {
    color: #3182ce;
  }

  h2 {
    color: white;
  }
}

.mainContent {
  flex: 1;
  max-height: calc(100vh - var(--headerHeight));
  overflow: hidden;
  overflow-y: auto;
  flex: 1;
  padding-right: 1vw;
}

.card {
  background-size: cover;
  background-color: white;
  padding: 12px;
  font-size: 1rem;
  font-weight: 400;
  margin: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}


.rightContent {
  display: flex;
  align-items: center;
  
}

img.search-input-3 {
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

.rightContent button:hover {
  background: #fff !important;
  border-color: #d9d9d9 !important;
  color: #000 !important;
}

.bbutton1 {
  padding-left: 45px !important;
}

.rightContent button {
  height: 40px;
  padding: 8px 20px 8px 16px;
  border-radius: 8px;
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  background: #fff !important;
  font-family: "DM Sans", sans-serif !important;
  margin: 0 0 0 15px;
}

input.Search-input {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  width: 240px;
  height: 40px;
  padding: 10px 45px 10px 40px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

img.search-input-1 {
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

img.search-input-2 {
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  position: absolute;
}

.search-custom {
  position: relative;
}

.headContent span {
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.headContent {
  color: #000000d9;
  // font-family: Fixel Display;
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
}
.headContentn {
  color: #000000d9;
  // font-family: Fixel Display;
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
  text-align: left;
}

img.filter-drop {
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  /* right: 15px; */
  position: absolute;
}

.flex-top-filter {
  position: relative;
}

.flex-top-filter .ant-btn-default.buttonf {
  min-width: 100px;
}

.flex-top-filter .ant-btn-default.buttonf img.filter-drop {
  right: 10px;
}

.flex-right {
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 99;
  display: flex;
  align-items: center;
}

.abs-1 {
  position: absolute;
  right: -9px;
  top: 35px;
  background: #fff;
  padding: 15px;
  border: 1px solid #edf2f7;
  border-radius: 12px;
  min-width: 313px;
}

.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  margin: 0 0 5px 0;
}

.bbutton {
  height: 40px;
  padding: 8px 20px 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  background: #fff !important;
  font-family: "DM Sans", sans-serif !important;
  margin: 0 0 0 15px;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.bbutton:hover {
  background: #fff !important;
  border-color: #d9d9d9 !important;
  color: #000 !important;
}

.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .leftC {
    display: flex;
    gap: 8px;
  }

  .rightC {
    margin-left: auto;
    /* Pushes the "Apply Filters" button to the right */
  }
}

.css-cqaibf-MuiPaper-root-MuiAppBar-root {
  background-color: white !important;
  box-shadow: none !important;
}

.custom-table .ant-table-thead > tr > th {
  text-transform: capitalize;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  font-family: "Inter", sans-serif;
  color: #00000080;
  white-space: nowrap;
}


.custom-table .ant-table-tbody > tr > td {
  color: #2d3748;
  border-bottom: 1px solid #e2e8f0;
  /* font-size: 1rem; */
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
}


.custom-table .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #ffffff;
}

.custom-table .ant-table-row {
  height: 2vh !important;
  /* Set the height of the rows */
}

.custom-table {
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 10px;
}

h4 {
  margin-left: -1.5vw;
  margin-top: 0.8vw;
}

h3 {
  margin-top: 0.8vw;
}


.ordercreation {
  background-size: cover;
  display: flex;
  
  justify-content: space-between;

  .cardcart1 {
    margin: 20px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-color: white;
    padding: 12px;
    width: 50%;

    .cardHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;


    }
  }

  .cardcart2 {
    margin: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-color: white;
    padding: 12px;
    width: 50%;
  }
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker__portal {
  position: fixed;
  z-index: 9999;
}




.summarycontainer{
  display:flex;
  align-items:center;
  

  .summarycontent{
      background-size: cover;
      margin-right: 2em;
      display: flex;
      flex:1;
      justify-content: space-between;
      box-shadow: 3px 3px 2px 0px rgba(227,218,218,1);
      height:110px;
      gap: 15px;

      .left{
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      }
      .title{    
        font-size: 1.1em;          
          font-weight: bold;
          color:darken(white,40%);
          text-transform: uppercase;
      }
      .count{    
          font-size: 28px;
          font-weight: 300;
          color:black;

          text-transform: uppercase;
      }
      .link{    
          width: max-content;
          font-size: 1rem;       
         
          color:black;


      }
      &:last-child{
          margin-right: 0;
      }
  }
}

.dashboard-ui-st{

  display:flex;
  gap:1vw;
  justify-content: space-between;

  
  .dashboardcard {
    margin: 20px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  
  .dashboardfigure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 4px; /* Inherit border radius from parent */
    padding: 10px; /* Adjust padding as needed */
  }


.dashboardinventory{
  background-size: cover;
  border-radius: 4px;
  border: #cecece;
  border-width: thick;
  width: 100vw;
  height: 50vh;
  box-shadow: 3px 3px 2px 0px rgba(227,218,218,1);
}
.titleinventory{    
  font-size: 1.5rem;
  font-weight: bold;
  color:darken(white,40%);
  text-transform: uppercase;
  margin-left: 40%;

}

.titleline{    
  font-size: 1.5rem;
  font-weight: bold;
  color:darken(white,40%);
  text-transform: uppercase;
  margin-left: 30%;

}

.title{    
  font-size: 1.5rem;
  font-weight: bold;
  color:darken(white,40%);
  text-transform: uppercase;
}


.link{    
  width: max-content;
  font-size: 1rem;
 
  color:black;
}
&:last-child{
  margin-right: 0;
}


}

.imageview{
  width:100px;
  height:100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  margin-left: 40%;
  box-shadow: 3px 3px 2px 0px rgba(227,218,218,1);
}


.tabContent {
  display: flex;
  width: 100 vw ;
  height: 80 vh;
  overflow: hidden; /* Prevent overflow */
}

.leftPane {
  flex: 1;
  width: 50%;
  padding-right: 2px; /* Add padding to create space between the panes */
}

.rightPane {
  flex: 1;
  width: 50%;
  overflow: auto; /* Enable scrolling for overflowing content */
}

.diagram {
  display: flex;
  width: 100 vw ;
  height: 500 vh;
  overflow: hidden; /* Prevent overflow */
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.login-content {
  display: flex;
  align-items: center;
  width: 600px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-container {
  flex: 1;
  padding: 40px;
}

.header {
  text-align: center;
  margin-bottom: 10px;
  color: #1890ff;
  font-size: 24px;
  font-weight: bold;
}

.logo-container {
  flex: 1;
  background-color: #1890ff;
  border-radius: 8px 0 0 8px;
}

.logo {
  width: 100%; /* Adjust the width of the logo to cover the container */
  height: auto;
}

.form-item {
  margin-bottom: 10px;
}

.login-button {
  width: 100%;
  border-radius: 4px;
  background-color: #1890ff;
  color: #fff;
}

.login-button:hover {
  background-color: #40a9ff;
}

.link {
  display: block;
  text-align: center;
  color: #1890ff;
}

.link:hover {
  color: #40a9ff;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .login-content {
    flex-direction: column;
    width: 80%;
  }

  .form-container {
    padding: 20px;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .logo-container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 8px;
  }

  .logo {
    width: 150px;
  }
}

.cardcalendar {
  background-size: cover;
  background-color: white;
  font-size: 1rem;
  font-weight: 400;
  margin: 20px;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/////////////////////////////////////////////////////
.custom-layout {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
}

.card-addition1 {
  margin-right: 10px;
}

.card-addition2 {
  margin-left: 10px;
}

.product-card {
  width: 100%;
  max-width: 1200px;
  /* Adjust the maximum width as needed */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-card-header {
  // padding: 16px;
  // border-bottom: 3px solid #3182CE;
  color: #3182ce;
}

.product-headContent {
  margin: 0;
}

.product-card-body {
  display: flex;
  flex-wrap: wrap;
  /* Allow items to wrap to the next line */
}

.product-verticalLine {
  flex: 0 0 1px;
  /* Vertical line */
  border: 1px solid #f0f0f0;
  height: 100%;
}

.product-leftSection {
  flex-basis: calc(75% - 1px);
  /* Set the initial width of each section */
  padding: 16px;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.product-rightSection {
  flex-basis: calc(25% - 1px);
  /* Set the initial width of each section */
  padding: 16px;
  box-sizing: border-box;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .product-leftSection,
  .product-rightSection {
    flex-basis: 100%;
    /* Make each section occupy the full width on smaller screens */
  }
}

.section-title {
  /* font-family: Fixel Display; */
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #000;
  margin: 20px 0 15px 0;
}

.sku-detailsh {
  display: flex;
  flex-direction: row;
  color: #3182ce;
}

.sku-details {
  display: flex;
  flex-direction: row;
}

.sku-details p {
  margin-right: 20px;
  /* Adjust spacing between details as needed */
}
.custom-tablep .ant-table-thead > tr > th {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  font-family: "Inter", sans-serif;
  color: #00000080;
  background: #fff;
}
.custom-tablep .ant-table-tbody > tr > td {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  font-family: "Inter", sans-serif;
  color: #000;
}
.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover {
  background: #fbf8fd;
}

.custom-tablep .ant-table-row {
  height: 2vh !important;
  /* Set the height of the rows */
  border: none;
}

.custom-tablep {
  width: 100%;
  border: none;
}

.tabs-container {
  display: flex;
}

.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Tab */
.tab {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

.tab:last-child {
  margin-right: 0;
}

.tab.active {
  background-color: #fff;
  border-bottom: 2px solid #3182ce;
  /* Change color as per your design */
}

/* Table container */
.table-container {
  border: 1px solid #ccc;
  border-radius: 0 0 8px 8px;
  padding: 20px;
}

.ant-tabs-tab-btn {
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #00000080;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
  text-shadow: none;
}




.ant-tag-yellow {
  border-color: #943fbc1a;
  background: #943fbc1a;
  color: #943fbc;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.ant-tag-blue {
  color: #59a8d4;
  border-color: #59a8d41a;
  background: #59a8d41a;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.ant-tag-green {
  color: #4aa785;
  background: #4aa7851a;
  border-color: #4aa7851a;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.ant-pagination.ant-pagination-mini.ant-table-pagination li a {
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.ant-pagination
  .ant-pagination-item-active {
  border: none;
}

.ant-pagination
  .ant-pagination-item-active
  a {
  color: #000000;
  background: #0000000d;
  border: none !important;
}

.filter_line {
  display: flex;
  align-items: center;
}

.buttonfilter {
  cursor: pointer;
}

.sort {
  display: flex;
  align-items: center;
}

.sort p {
  margin: 0 0 0 10px;
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}

.sort-img {
  width: 20px;
  margin: 0 0 0 10px;
  cursor: pointer;
}

.filter-icon {
  width: 20px;
  position: relative;
  bottom: 2px;
  margin: 0 5px 0 10px;
  cursor: pointer;
}

.sort-header {
  display: flex;
}

.dropdown-content-sort {
  width: 150px;
  position: absolute;
  right: 0;
  top: 30px;
  background: #fff;
  padding: 0;
  border: 1px solid #edf2f7;
  border-radius: 12px;
}

.abs-1 .ant-select-single.ant-select-show-arrow.ant-select-show-search {
  margin: 0 0 5px 0 !important;
  width: 100% !important;
}

.button-flex-color button {
  height: 40px;
  gap: 8px;
  border-radius: 8px;
  width: 100%;
  background: #f3f3f3;
  border: none;
}

.button-flex-color {
  display: flex;
  justify-content: space-between;
}

.button-flex-color .button-2-color {
  background: #000;
}

.button-flex-color .button-2-color:hover {
  background: #000 !important;
  color: #fff !important;
  opacity: 0.8;
}

.button-flex-color button:hover {
  background: #f3f3f3 !important;
  color: #000 !important;
  opacity: 0.8;
}

img.arrow_up {
  width: 14px;
}

.arrow_down {
  width: 14px;
}

.dropdown-option img {
  margin: 0 10px 0 0;
  position: relative;
  bottom: 2px;
}

.dropdown-option {
  padding: 10px 15px;
  cursor: pointer;
}
.dropdown-option:hover span{
  color: #000 !important;
}
.dropdown-option span {
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #00000080;
}

.dropdown-content .dropdown-option:nth-child(2) span {
  color: #000;
}

.sort-header p {
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

img.trash_icon {
  width: 20px;
  display: none;
}

.ant-table-content table tbody tr:hover img.trash_icon {
  display: block;
}

.delete_line {
  display: flex;
  align-items: center;
}

img.delete-icon {
  width: 20px;
  position: relative;
  bottom: 2px;
  margin: 0 5px 0 10px;
  cursor: pointer;
}
.ant-checkbox-input {
  background: url(../src/assets/images/uncheck.png);
  width: 20px;
  height: 20px;
  background-size: 20px;
  appearance: none;
  position:  relative !important;
  opacity: 1 !important;
}

.ant-checkbox-input:checked {
  background: url(../src/assets/images/check.png);
  width: 20px;
  height: 20px;
  background-size: 20px;
  appearance: none;
  position:  relative !important;
  opacity: 1 !important;
}


.ant-checkbox .ant-checkbox-inner{
  display: none !important;
}
.MysideBar ul.css-ewdv3l li a {
  /* font-family: Fixel Text; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #00000080;
  border-radius: 8px;
}
.MysideBar ul.css-ewdv3l li a:hover,
.activeMenuItem a {
  -webkit-box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 39%);
  -moz-box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 39%);
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 39%);
}
.MysideBar .ps-submenu-content.ps-open.css-z5rm24 {
  background: transparent;
}
.back-div a.product-headContent {
  height: 40px;
  padding: 8px 20px 8px 16px;
  border-radius: 8px;
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  background: #fff !important;
  font-family: "DM Sans", sans-serif !important;
  margin: 0 0 0 0;
  display: inline-block;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.39);
}
img.bk-product-img {
  width: 15px;
  position: relative;
  bottom: 2px;
  margin: 0 5px 0 0;
}
.allnewstructure {
  padding: 30px;
}
p.span-div {
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #00000080;
}
p.span-div span {
  color: #00000080 !important;
}
p.span-div .ant-tag.ant-tag-has-color{
  color: #943fbc !important;
  background: transparent !important;
}



img.bk-product-img-1 {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
  margin: 0 15px 0 0;
}
p.PrimaryVendor-p1 {
  /* font-family: Fixel Text; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0 0 2px 0;
  color: #000;
}
p.PrimaryVendor-p2 {
  // font-family: Fixel Text; 
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin: 0 0 0 0;
  color: rgba(0, 0, 0, 0.5019607843) !important;
  cursor: pointer;
}
.email_drop {
  background: #00000099;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 12px;
  position: absolute;
  right: -180px;
  bottom: 10px;
  display: none;
}
.example-p {
//  font-family: Fixel Text;
 font-size: 14px;
 font-weight: 400;
  text-align: left;
  color: #fff;
}
.PrimaryVendor-p2:hover .email_drop {
  display: block;
}
.custom-media {
  display: flex;
  align-items: center;
}
.custom-media.new-media {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 7px 0 0 0;
}
.right-data {
  display: flex;
  justify-content: flex-end;
}
img.graph-img {
  width: 80px;
}
.common-div {
  border: 1px solid #d9d9d9;
  padding: 22px;
  height: 100%;
  border-radius: 12px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  background: #fff;
}

img.graph-img {
  width: 80px;
}

p.total-sale-1 {
  /* font-family: Fixel Text; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000 !important;
  margin: 0 0 30px 0;
}

p.total-sale-2 {
  /* font-family: Fixel Display; */
  margin: 0;
}
p.total-sale-2 span {
  background: transparent !important;
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  padding: 0;
}
.common-div {
  border: 1px solid #d9d9d9;
  padding: 22px;
  height: 100%;
  border-radius: 12px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-wrap: wrap;
}
img.graph-img {
  width: 80px;
}
p.total-sale-1 {
  /* font-family: Fixel Text; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000 !important;
  margin: 0 0 30px 0;
}
p.total-sale-2 {
  /* font-family: Fixel Display; */
  margin: 0;
}
p.total-sale-2 span {
  background: transparent !important;
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  padding: 0;
}
p.total-sale-3 {
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0;
  color: #00000080;
}
img.green-img {
  width: 12px;
  margin: 0 5px 0 0;
  position: relative;
  bottom: 1px;
}
span.green-span {
  color: #389e30;
  display: inline-block;
  margin: 0 5px 0 0;
}
.common-div {
  border: 1px solid #d9d9d9;
  padding: 22px;
  height: 100%;
  border-radius: 12px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
img.graph-img {
  width: 80px;
}
p.total-sale-1 {
  /* font-family: Fixel Text; */
  font-size: 14px;;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000 !important;
  margin: 0 0 30px 0;
}
p.total-sale-2 {
  /* font-family: Fixel Display; */
  margin: 0;
}
p.total-sale-2 span {
  background: transparent !important;
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  padding: 0;
}
p.total-sale-3 {
  /* font-family: Fixel Text; */
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0;
  color: #00000080;
}
img.green-img {
  width: 12px;
  margin: 0 5px 0 0;
  position: relative;
  bottom: 1px;
}
span.green-span {
  color: #389e30;
  display: inline-block;
  margin: 0 5px 0 0;
}
span.red-span {
  color: #da2525;
  display: inline-block;
  margin: 0 5px 0 0;
}
img.width-80 {
  width: 50px;
}
img.width-60 {
  width: 30px;
}
p.SalesandForecast {
  /* font-family: Fixel Display; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin: 0 0 0 0;
}
.line-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px 0;
}
.common-div-1 {
  border: 1px solid #d9d9d9;
  padding: 22px;
  border-radius: 12px;
  background: #fff;
  width: 100%;
  height: 100%;
}
ul.common-ul-media {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}
ul.common-ul-media li {
  width: 50%;
  margin: 0px 0;
  padding: 30px 30px;
}
img.bk-product-img-11 {
  width: 58px;
  height: 58px;
  border-radius: 70px;
  object-fit: cover;
  margin: 0 15px 0 0;
}
p.PrimaryVendor-p11 {
  /* font-family: Fixel Display; */
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin: 0;
  color: #000;
}
p.PrimaryVendor-p21 {
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0;
  color: #00000080;
}
.mar-30-urgent {
  margin-bottom: 30px;
}
img.circle-img {
  position: absolute;
  width: 30px;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
}

.right-data.fixed-bottom-profile {
  position: fixed;
  bottom: 20px;
  left: 21px;
  background: #fafafa;
}

img.bk-dots-img-2 {
  width: 3px;
  cursor: pointer;
  position: fixed;
  left: 205px;
  bottom: 40px;
}

.dropdown-icon {
  width: 17px;
  position: absolute;
  left: 15px;
  z-index: 99;
  padding: 0 !important;
  top: 10px;
}
.dropdown-content {
  cursor: pointer;
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 115px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: -120px;
  bottom: 0;
  padding: 0 0 0 20px;
  border-radius: 8px;
}

.dropdown-content img {
  display: inline-block;
  vertical-align: middle;
}

.dropdown-content span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.dropdown-content img,
.dropdown-content span {
  padding: 12px 16px;
  text-decoration: none;
  color: black;
}

.dropdown-content img:hover,
.dropdown-content span:hover {
  background-color: #f1f1f1;
}

ul.common-ul-media li {
  border-right: 1px solid #d9d9d9;
}

ul.common-ul-media li:nth-child(3),
ul.common-ul-media li:nth-child(5) {
  border-right: 1px solid transparent;
}

ul.common-ul-media li:nth-child(2),
ul.common-ul-media li:nth-child(3) {
  border-bottom: 1px solid #d9d9d9;
}
img.no-data {
  width: 100px;
  margin: auto;
  display: block;
  margin-top: 60px;
}

.dropdown-option:hover {
  background: #FBF8FD;
}

.recharts-responsive-container.lineChart {
  width: 100% !important;
}
.ant-tabs .ant-tabs-ink-bar {
  background: #000 !important;
}
.card.custom-card {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}
.card.custom-card .cardHeader {
  margin: 0px 0 30px 0;
}
.predicted-tab .ant-tabs-nav {
  margin: 0;
}
.predicted-tab .ant-tabs-tab {
  border: none !important;
  background: transparent !important;
}
.custom-tablep table tr td:last-child, .custom-tablep table tr th:last-child {
  text-align: right;
}

.predicted-tab .ant-tabs-tab.ant-tabs-tab-active {
  background: #fff !important;
}

ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right {
  background: white;
  margin: 0;
  padding: 10px 15px;
}
.ant-table-content thead tr th:before {
  display: none;
}

.custom-tablep .ant-table-tbody tr td:last-child {
  width: 75px;
  text-align: left;
}

.custom-tablep .ant-table-tbody tr td:first-child:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;  
  height: 15px;
  content: "";
}

.custom-tablep .ant-table-tbody tr td:first-child:after {
  position: absolute;
  left: 15px;
  top: 16px;
  content: "";
  width: 14px;
  height: 15px;
  background-image: url(assets//images//Union.png);
  background-size: contain;
}
.custom-tablep .ant-table-tbody tr td:first-child{
padding-left: 40px;
}
.ant-pagination .ant-pagination-item-active a {
  background: #FBF8FD;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 28px;
  height: 28px;
  margin: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}
@media (max-width: 1024px) {
  .common-div-right {
    margin: 15px 0 0px 0;
  }
  ul.common-ul-media .custom-media {
    flex-wrap: wrap;
  }
  ul.common-ul-media li {
    width: 50%;
    margin: 0px 0;
    padding: 15px 15px;
  }
  img.bk-product-img-11 {
    margin: 0 0px 10px 0;
  }
}

@media (max-width: 1400px) {
  img.width-80 {
    width: 55px;
  }
}

@media (max-width: 991px) {
  .flex-right {
    position: static;
}
  .common-div {
    min-height: 200px;
    margin: 0 0 30px 0;
    height: auto;
  }
  .h-auto-demo{
    height: 100% !important;
  }
  ul.recharts-default-legend {
    position: absolute;
    right: 325px;
    bottom: 26px;
}
  .common-div-1 {
    height: auto;
    margin: 0 0 30px 0;
  }
}

@media (max-width: 767px) {
  .rightContent button {
      margin: 15px 15px 0 0px;
  }
  .h-auto-demo{
    height: auto !important;
  }
  .right-data {
    justify-content: flex-start;
    margin: 15px 0 30px 0;
  }
  ul.common-ul-media li {
    border: none !important;
    width: 100%;
    padding: 15px 0px;
  }
  img.bk-product-img-11 {
    margin: 0 15px 0px 0;
  }
  img.circle-img {
    display: none;
  }
  .mar-30-urgent {
    margin-bottom: 0;
  }
  .common-div-1 {
    height: auto;
    margin: 0 0 30px 0;
  }
}


.estimate-pdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}


.toolbar {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 8px;
  z-index: 1;
}

.tool-options {
  position: absolute;
  top: 60px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1;
}

.pen-size, .eraser-size, .pen-color {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ant-tabs {
  position: relative;
  z-index: 1000; /* Make sure this is high enough */
}
.course-manager {
  padding: 20px;
  background-color: #f7f7f7;
  position: relative;
}

.add-section-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.add-section-btn {
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: #1890ff;
  color: white;
  border: none;
}

.section-card {
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.lesson-collapse .ant-collapse-header {
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
}

.lesson-title {
  font-weight: 500;
  color: #333;
}

.lesson-panel {
  padding: 15px;
  border-top: 1px solid #ddd;
}

.lesson-details {
  margin-top: 20px;
}

.upload-video-btn {
  margin-bottom: 15px;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  background-color: #1890ff;
  color: white;
  border: none;
}

.lesson-content {
  margin-top: 20px;
}

.video-thumbnail {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #ddd;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.video-thumbnail:hover {
  transform: scale(1.05);
}

.video-thumbnail .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.write-up {
  margin-top: 10px;
}

.write-up-btn {
  font-size: 16px;
  color: #1890ff;
}

.write-up p {
  color: #555;
  font-size: 14px;
}